@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(https://db.onlinewebfonts.com/c/954a505bdd1fd46577f8b93badf61c8c?family=DIN+Condensed);
@import url(https://fonts.cdnfonts.com/css/din-alternate);

.grecaptcha-badge {
    visibility: hidden;
}

.imageStackFront {
	grid-area: 2/1/4/3;
}

.imageStackBack {
	grid-area: 1/2/3/4;
}

@media (min-aspect-ratio: 16/9) {
	.video-bg {
	  /* height = 100 * (9 / 16) = 56.25 */
	  height: 56.25vw;
	}
  }
  @media (max-aspect-ratio: 16/9) {
	.video-bg {
	  /* width = 100 / (9 / 16) = 177.777777 */
	  width: 177.78vh;
	}
}

.carousel .carousel-status {
	@apply !text-[12px] bg-primary-dark/80 rounded-[2px] backdrop-blur-sm !px-2 !py-0 !top-3 !right-3 !shadow-none !drop-shadow-none
}

input[type="search"] {
	-webkit-appearance: none;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

/********************
 * Hamburger button *
 ********************/

.ham {
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	transition: transform 400ms;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.hamRotate.active {
	transform: rotate(45deg);
}

.hamRotate180.active {
	transform: rotate(180deg);
}

.line {
	fill:none;
	transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
	stroke:#FFF;
	stroke-width:3.5;
	stroke-linecap:round;
}

.ham8 .top {
	stroke-dasharray: 40 160;
}

.ham8 .middle {
	stroke-dasharray: 40 142;
	transform-origin: 50%;
	transition: transform 400ms;
}

.ham8 .bottom {
	stroke-dasharray: 40 85;
	transform-origin: 50%;
	transition: transform 400ms, stroke-dashoffset 400ms;
}

.ham8.active .top {
	stroke-dashoffset: -64px;
}

.ham8.active .middle {
	transform: rotate(90deg);
}

.ham8.active .bottom {
	stroke-dashoffset: -64px;
}
